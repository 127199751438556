export default {
  breakpoints: [
    {
      name: 'mobile',
      max: 768,
    },
    {
      name: 'tablet',
      max: 1023,
    },
    {
      name: 'desktop',
      max: 1215,
    },
    {
      name: 'widescreen',
      max: 1407,
    }
  ]
}