import Splide from '@splidejs/splide';
import Breakpoints from './breakpoints';
import Viewport from './viewport';

export default {

  init() {
    const elms = document.querySelectorAll('.splide');

    elms.forEach(elm => {
      const gap = elm.getAttribute('data-splide-gap');
      let padding = elm.getAttribute('data-splide-padding');
      const paddingValue = {};
      if (padding) {
        padding = padding.replace('{', '').replace('}', '')
        const direction = padding.split(':')[0];
        const amount = padding.split(':')[1];
        paddingValue[direction] = amount;
      }
      const viewportWidth = Viewport.get_viewport().split('px')[0];
      if (viewportWidth < 768) {
        const settings = JSON.parse(elm.getAttribute('data-splide'));
        if (elm.classList.contains('iko-content-slider')) {
          settings['perPage'] = 1;
          settings['perMove'] = 1;
        } else if (elm.classList.contains('iko-image-slider')) {
          settings['perPage'] = 2;
          settings['perMove'] = 2;
        }
        elm.setAttribute('data-splide', JSON.stringify(settings));
      }

      new Splide(elm, {
        arrowPath: 'M10.813,9.281,4.094,16l6.719,6.719,1.406-1.437L7.938,17H28V15H7.938l4.281-4.281Z',
        gap: gap,
        padding: Object.keys(paddingValue).length ? paddingValue : 0,
      }).mount();
    });
  },

  get_breakpoint_settings({ initialNumberOfSlides: initialNumberOfSlides, maxNumberOfSlides: maxNumberOfSlides }) {
    const breakpoints = Breakpoints.breakpoints;
    let slidesPerBreakpoint = {};

    const breakpointSizes = breakpoints.map(breakpoint => breakpoint.max).sort((a, b) => a - b);

    breakpointSizes.forEach(size => {
      slidesPerBreakpoint[size] = {
        perPage: initialNumberOfSlides < maxNumberOfSlides ? initialNumberOfSlides++ : maxNumberOfSlides,
      }
      slidesPerBreakpoint[size]['perMove'] = slidesPerBreakpoint[size]['perPage'];
    });

    return slidesPerBreakpoint;
  }
}